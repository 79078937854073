import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/dashboard/Dashboard';
import Overview from './components/dashboard/Overview';
import Profile from './components/dashboard/Profile';
import Settings from './components/dashboard/Settings';
import { AuthProvider } from './components/utils/AuthContext';
import PrivateRoute from './components/utils/PrivateRoute';
import API from './components/dashboard/API';
import CalendlyWidget from './components/CalendlyWidget';

// Custom Theme
const theme = extendTheme({
  fonts: {
    heading: "'Nunito Sans', sans-serif",
    body: "'Nunito Sans', sans-serif",
  },
});

// App Component
const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >
            <Route index element={<Overview />} />
            <Route path="api" element={<API />} />
            <Route path="profile" element={<Profile />} />
            <Route path="settings" element={<Settings />} />
            <Route path="karas" element={<CalendlyWidget />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  </ChakraProvider>
);

export default App;
