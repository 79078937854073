import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const Profile = () => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h1" size="xl" mb={6}>
        Profile
      </Heading>
      <Text fontSize="xl" mb={4}>
        Profile content goes here. fitf8tcvou
      </Text>
    </Box>
  );
};

export default Profile;
