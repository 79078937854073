import React from 'react';

const CalendlyWidget = () => {
  return (
    <iframe
      src="https://calendly.com/realalexkaras/30min"
      style={{ minWidth: '320px', height: '700px', border: 'none' }}
      scrolling="no"
      title="karaslikesboys"
    ></iframe>
  );
};

export default CalendlyWidget;
