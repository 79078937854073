// components/Navbar.js
import React from 'react';
import { Box, Flex, HStack, IconButton, Image, Button, useDisclosure, Stack, Text } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import AuthGunLogo from '../../assets/authgunicon.png';  // Importing the logo

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box px={4} color="black" borderBottom="1px" borderColor="gray.200">
      <Flex h={20} alignItems="center" justifyContent="space-between" maxW="1000px" mx="auto">
        <HStack alignItems="center">
          <RouterLink to="/">
            <Flex alignItems="center">
              <Image src={AuthGunLogo} alt="AuthGun Logo" boxSize="40px" />
              <Text fontSize="2xl" fontWeight="bold" ml={2}>AuthGun</Text>
            </Flex>
          </RouterLink>
        </HStack>
        <IconButton
          size="lg"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack
          as="nav"
          spacing={4}
          display={{ base: 'none', md: 'flex' }}
          mx="auto"
        >
          <RouterLink to="/">Home</RouterLink>
          <RouterLink to="/features">Features</RouterLink>
          <RouterLink to="/pricing">Pricing</RouterLink>
          <RouterLink to="/about">About</RouterLink>
        </HStack>
        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
          <Button as={RouterLink} to="/login" variant="solid" colorScheme="blue" size="md" mr={4} fontWeight="bold">
            Login
          </Button>
          <Button as={RouterLink} to="/signup" variant="outline" colorScheme="blue" size="md" fontWeight="bold">
            Signup
          </Button>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as="nav" spacing={4}>
            <RouterLink to="/">Home</RouterLink>
            <RouterLink to="/features">Features</RouterLink>
            <RouterLink to="/pricing">Pricing</RouterLink>
            <RouterLink to="/about">About</RouterLink>
            <Button as={RouterLink} to="/login" variant="solid" colorScheme="blue" size="md" fontWeight="bold">
              Login
            </Button>
            <Button as={RouterLink} to="/signup" variant="outline" colorScheme="blue" size="md" fontWeight="bold">
              Signup
            </Button>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default Navbar;
