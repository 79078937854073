// components/Home.js
import React from 'react';
import Hero from './Hero';
import Footer from '../utils/Footer';
import Navbar from '../utils/Navbar';

const Home = () => (
  <div>
    <Navbar/>
    <Hero />
    <Footer/>
  </div>
);

export default Home;
