// components/Hero.js
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Hero = () => (
  <Box textAlign="center" py={10} px={6}>
    <Heading as="h1" size="2xl" mb={6}>
      Welcome to Our SaaS Platform
    </Heading>
    <Text fontSize="xl" mb={4}>
      Discover the features and benefits of our platform.
    </Text>
    <Button as={Link} to="/signup" colorScheme="teal" size="lg" mr={3}>
      Get Started
    </Button>
    <Button as={Link} to="/login" colorScheme="teal" size="lg">
      Login
    </Button>
  </Box>
);

export default Hero;
