import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

const API = () => {

  return (
    <Box>
      <Heading as="h1" size="xl" mb={6}>
        API
      </Heading>
    </Box>
  );
};

export default API;
