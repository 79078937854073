import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const Settings = () => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h1" size="xl" mb={6}>
        Settings
      </Heading>
      <Text fontSize="xl" mb={4}>
        Settings content goes here.
      </Text>
    </Box>
  );
};

export default Settings;
