import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useAuth } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';

const Overview = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Box>
      <Heading as="h1" size="xl" mb={6}>
        Overview
      </Heading>
      <Text fontSize="xl" mb={4}>
        Here is an overview of your account and activities.
      </Text>
      <Text fontSize="xl" mb={4}>
        Logged in as: {currentUser.email}
      </Text>
      <Button colorScheme="red" size="lg" onClick={handleLogout}>
        Logout
      </Button>
    </Box>
  );
};

export default Overview;
