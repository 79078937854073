import React, { useEffect, useState } from 'react';
import { Box, Flex, Image, Button, Text, Avatar } from '@chakra-ui/react';
import { useAuth } from '../utils/AuthContext';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { FaHome, FaUser, FaCog, FaChevronLeft, FaChevronRight, FaCode } from 'react-icons/fa';
import AuthGunLogo from '../../assets/authgunicon.png';

const tabData = [
  { icon: FaHome, label: 'Overview', path: '/dashboard' },
  { icon: FaCode, label: 'API', path: '/dashboard/api' },
  { icon: FaUser, label: 'Profile', path: '/dashboard/profile' },
  { icon: FaCog, label: 'Settings', path: '/dashboard/settings' },
];

const Dashboard = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      const tabIndex = tabData.findIndex(tab => tab.path === location.pathname);
      if (tabIndex !== -1) {
        setSelectedTab(tabIndex);
      }
    }
  }, [currentUser, navigate, location]);

  const handleTabClick = (index) => {
    setSelectedTab(index);
    navigate(tabData[index].path);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Flex height="100vh">
      <Box
        w={isCollapsed ? '80px' : '260px'}
        transition="width 0.3s"
        borderRight="1px solid"
        borderColor="gray.200"
        p={4}
      >
        <Flex
          alignItems="center"
          mb={4}
          justifyContent={isCollapsed ? 'center' : 'flex-start'}
          p={isCollapsed ? 0 : 4}
          my={isCollapsed ? 4 : 0}
        >
          <Image
            src={AuthGunLogo}
            alt="AuthGun Logo"
            boxSize='35px'
          />
          {!isCollapsed && <Text fontSize="2xl" fontWeight="bold" ml={3}>AuthGun</Text>}
        </Flex>
        <Flex direction="column">
          {tabData.map((tab, index) => (
            <Button
              key={index}
              leftIcon={<tab.icon fontSize="18px" />}
              justifyContent={isCollapsed ? 'center' : 'start'}
              alignItems="center"
              mb={3}
              pr={2}
              variant={selectedTab === index ? 'solid' : 'ghost'}
              onClick={() => handleTabClick(index)}
              fontSize="1.1rem"
            >
              {!isCollapsed && <Text ml={3}>{tab.label}</Text>}
            </Button>
          ))}
          <Button
            leftIcon={isCollapsed ? <FaChevronRight fontSize="18px" /> : <FaChevronLeft fontSize="18px" />}
            justifyContent={isCollapsed ? 'center' : 'start'}
            alignItems="center"
            mb={3}
            pr={2}
            variant="ghost"
            onClick={toggleSidebar}
            fontSize="1.1rem"
          >
            {!isCollapsed && <Text ml={3}>Collapse</Text>}
          </Button>
        </Flex>
      </Box>
      <Box flex="1" p={4}>
        <Flex justifyContent="flex-end" alignItems="center" mb={4}>
          <Avatar name={currentUser?.email} size="sm" bg="blue.500" color="white" />
          <Text ml={2}>{currentUser?.email}</Text>
        </Flex>
        <Outlet />
      </Box>
    </Flex>
  );
};

export default Dashboard;
