import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAho9hUJRaR2-gvIEiddOEVfWwvAtX5OPw",
  authDomain: "authgun.firebaseapp.com",
  projectId: "authgun",
  storageBucket: "authgun.appspot.com",
  messagingSenderId: "345856704156",
  appId: "1:345856704156:web:6035c7330f6fa42bc67759"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
